import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import background from "../assets/images/background.jpeg";
import tlo from "../assets/images/tlo.jpg";
import HeaderNav from "./HeaderNav";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = "Kariera na szpilkach";
    const description1 = this.props.data.description1;
    const description2 = this.props.data.description2;
    const description3 = this.props.data.description3;

    return (
      <header id="home">
        {/*<ParticlesBg type="cobweb" bg={true} color="#AF6464" />*/}
        <img src={tlo} alt="background" style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }} />

        <HeaderNav />
        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <div style={{ background: 'rgba(0, 0, 0, 0.5)', padding: '20px', borderRadius: '10px' }}>
                <h1 className="responsive-headline" style={{ textShadow: '2px 2px 4px #000000' }} >{name}</h1>
                <h2 style={{ color: 'white', marginBottom: '30px', textShadow: '2px 2px 4px #000000' }}>{description3}</h2>
              </div>
            </Fade>
            <Fade bottom duration={1200}>
              <div style={{ background: 'rgba(0, 0, 0, 0.5)', padding: '20px', borderRadius: '10px' }}>
                <h2 style={{ color: 'white', textShadow: '2px 2px 4px #000000' }}>{description1}</h2>
                <h3 style={{ color: 'white', textShadow: '2px 2px 4px #000000' }}>{description2}</h3>
              </div>
            </Fade>
            <hr />
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;

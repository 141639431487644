import inaug1 from "../assets/images/img0.jpg";
import inaug2 from "../assets/images/img1.jpg";
import zdj1 from "../assets/images/1.jpeg";
import zdj2 from "../assets/images/2.jpeg";
import plakat from "../assets/images/plakat.jpg";
import styles from "./Description.module.css";
import Slide from "react-reveal";

const Desc = () => {
  return (
    <section id="resume">
      <Slide left duration={1300}>
        <div className={styles.grid_container}>
          <div className={styles.image}>
            <img src={zdj2} alt="Zdjęcie 1" />
          </div>
          <div className={styles.image_description}>
            <p>W dzisiejszym świecie coraz więcej kobiet odkrywa potencjał biznesowy w internecie. Motywacją do stworzenia własnego biznesu są pragnienie niezależności, samorealizacji i inspiracja sukcesem innych kobiet online. Dzięki globalnemu zasięgowi i niskim kosztom startowym, kobiety mogą rozwijać marki, które odzwierciedlają ich pasje i umiejętności, inspirując innych do podjęcia własnych wyzwań.
            </p>
          </div>

          <div className={styles.image_description}>
            <p>Kobiety, które wspierają siebie nawzajem to jest coś na co możemy patrzeć całymi dniami. Do tego chcemy dążyć i dawać możliwość rozwijania się. Dzięki takim spotkaniom staramy się przekazać i uświadomić, że każda kobieta, bez względu na jakim etapie życia się znajduje, jest w stanie osiągną naprawdę wiele. Jest wiele przeszkód, na które możemy się natknąć w rozwijaniu swoich celów, ale nie ma rzeczy, której nie da się przełamać. Najlepszy sposób udowodnienia to spotkanie "twarzą w twarz" z osobami, które przeszły naprawdę wiele i są spełnione w tym co robią.
            </p>
          </div>
          <div className={styles.image}>
            <img src={zdj1} alt="Zdjęcie 2" />
          </div>
        </div>
        {/*<div className={styles.plakat}>
          <img src={plakat} alt="Plakat 1" />
        </div>*/}
      </Slide>
    </section >
  );
};

export default Desc;

import React, { useState } from 'react';
import Fade from 'react-reveal';
import logo1 from '../assets/images/Logo 1.png';
import logo2 from '../assets/images/Logo 2.png';
import sponsor from '../assets/images/sponsor.png';
import styles from './Description.module.css';
import Slide from 'react-reveal/Slide';

function Submit(e, setSubmissionStatus, setButtonText, setIsButtonDisabled) {
    e.preventDefault();
    const formEle = document.querySelector('form');
    const formData = new FormData(formEle);

    const firstName = formData.get('Name');
    const email = formData.get('Email');

    const requestBody = {
        firstName: firstName,
        email: email
    };

    setButtonText('Wysyłanie...');
    setIsButtonDisabled(true);

    fetch('https://karieranaszpilkach.azurewebsites.net/Registration', {
        redirect: "follow",
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
    })
        .then((response) => {
            if (response.ok) {
                console.log('Form submitted successfully!');
                setSubmissionStatus(true);
            } else {
                console.error('Form submission failed.');
                setButtonText('Wyślij');
                setIsButtonDisabled(false);
            }
        });
}

function Form() {
    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [buttonText, setButtonText] = useState('Wyślij');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    return (
        <section id="register">
            <Fade left duration={1000} distance="40px">
                <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                    {submissionStatus ? (
                        <div className="six columns form-container">
                            <div className="submission-announcement">
                                <p style={{ fontFamily: 'arial', fontWeight: 'bold', textAlign: 'center' }}>
                                    Dziękujemy za zgłoszenie. Przypomnimy się o wydarzeniu mailowo :)
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="three columns">
                                {/* Left image */}
                                <img id="loga" src={logo1} alt="Background" className="logo" width={'200px'} style={{ marginTop: '40%' }} />
                            </div>
                            <div className="six columns form-container">
                                {/* Form */}
                                <form className="form" style={{ display: 'grid', justifyContent: 'center' }} onSubmit={(e) => Submit(e, setSubmissionStatus, setButtonText, setIsButtonDisabled)}>
                                    <h1 style={{ fontSize: '28px' }}>Rejestracja na wydarzenie</h1>
                                    <div className="input-style">
                                        <label htmlFor="name">Imie *</label>
                                        <input type="text" id="name" name="Name" placeholder="Twoje imię" required />
                                    </div>
                                    <div className="input-style">
                                        <label htmlFor="email">Email *</label>
                                        <input type="email" id="email" name="Email" placeholder="Twój adres email" required />
                                    </div>
                                    <div className="input-style" style={{ textAlign: 'center' }}>
                                        <input className="button" id="item-but" type="submit" style={{ fontFamily: 'arial' }} value={buttonText} disabled={isButtonDisabled} />
                                    </div>
                                </form>
                            </div>
                            <div className="three columns">
                                {/* Right image */}
                                <img id="loga" src={logo2} alt="Background" className="logo" width={'200px'} style={{ marginTop: '40%' }} />
                            </div>
                        </>
                    )}
                </div>
            </Fade>
            <Slide left duration={1300}>
                <div className={styles.plakat}>
                    <h1 style={{ fontSize: '28px' }}>Sponsorzy</h1>
                    <img src={sponsor} style={{ maxWidth: '250px' }} alt="Plakat 1" />
                </div>
            </Slide>
        </section>
    );
}

export default Form;

import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepics = this.props.data.profilepics; // Assuming profilepics is an array of image filenames
    const bios = this.props.data.bios; // Assuming bios is an array of corresponding bios

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            {profilepics.map((profilepic, index) => (
              <div key={index} className="three columns">
                <img
                  className="profile-pic"
                  src={"images/" + profilepic}
                  alt={`${name} Profile Pic ${index + 1}`}
                />
                <center><p>{bios[index]}</p></center>
              </div>
            ))}
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;

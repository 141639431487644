import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from "./Components/Admin";
import Poprzednie from "./Components/Poprzednie";


ReactDOM.render(
  <Router>
    <div className="App">
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/poprzednie-wydarzenia" element={<Poprzednie />} />
        <Route path="*" element={<App />} />
      </Routes>
    </div>
  </Router>,
  document.getElementById('root')
);
reportWebVitals();

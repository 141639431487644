import React from "react";

function isSubpage(url) {
    return window.location.pathname === url;
}

const HeaderNav = () => {
    const isHomepage = window.location.pathname === "/";
    const currentUrl = window.location.pathname;

    return (
        <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                Pokaż menu
            </a>
            <a className="mobile-btn" href="#nav-off" title="Hide navigation">
                Ukryj menu
            </a>

            <ul id="nav" className="nav">
                <li className={isHomepage ? "current" : ""}>
                    <a className={isHomepage ? "smoothscroll" : ""} href="/#home">
                        HOME
                    </a>
                </li>

                <li>
                    <a className={isHomepage ? "smoothscroll" : ""} href="/#about">
                        O nas
                    </a>
                </li>

                <li>
                    <a className={isHomepage ? "smoothscroll" : ""} href="/#resume">
                        Opis
                    </a>
                </li>

                <li>
                    <a className={isHomepage ? "smoothscroll" : ""} href="/#register">
                        Rejestracja
                    </a>
                </li>

                <li>
                    <a href="/poprzednie-wydarzenia">
                        Poprzednie wydarzenia
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default HeaderNav;

import React, { useState, useEffect } from 'react';
import './Admin.css';

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() => {
        if (loggedIn) {
            fetchParticipants();
        }
    }, [loggedIn]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = async () => {
        setIsLoggingIn(true);
        const response = await fetch('https://karieranaszpilkach.azurewebsites.net/AuthApi', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (response.ok) {
            setLoggedIn(true);
            setIsLoggingIn(false);
        } else {
            alert('Nieprawidłowa nazwa użytkownika lub hasło.');
            setIsLoggingIn(false);
        }
    };

    const fetchParticipants = async () => {
        const response = await fetch('https://karieranaszpilkach.azurewebsites.net/Registration');
        const data = await response.json();
        setParticipants(data);
    };

    const handleGoBack = () => {
        setLoggedIn(false);
        window.location.href = '/';
    };

    return (
        <>
            <button style={{ width: '250px', left: '0', top: '0', margin: '10px', position: 'absolute' }} onClick={handleGoBack}>Powrót do strony głównej</button>
            <div className="container">
                {loggedIn ? (
                    <div className="list-box">
                        <h2>Lista uczestników</h2>
                        <table className="wide-table">
                            <thead>
                                <tr>
                                    <th>Imię</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {participants.map(participant => (
                                    <tr key={participant.id}>
                                        <td>{participant.firstName}</td>
                                        <td>{participant.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="login-box">
                        <h1>Logowanie</h1>
                        <input
                            type="text"
                            placeholder="Nazwa użytkownika"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                        <br />
                        <input
                            type="password"
                            placeholder="Hasło"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        <br />
                        <button disabled={isLoggingIn} onClick={handleLogin}>{isLoggingIn ? 'Logowanie...' : 'Zaloguj'}</button>
                    </div>
                )}
            </div>
        </>
    );
}

export default App;

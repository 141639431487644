import inaug1 from "../assets/images/img0.jpg";
import inaug2 from "../assets/images/img1.jpg";
import zdj1 from "../assets/images/jedna.jpg";
import zdj2 from "../assets/images/druga.jpg";
import zdj3 from "../assets/images/krzeslo.jpeg";
import zdj4 from "../assets/images/ciemno.jpg";
import zdj5 from "../assets/images/trzecia.jpg";
import zdj6 from "../assets/images/czwarta.jpg";
import plakat from "../assets/images/plakat.jpg";
import styles from "./Description.module.css";
import Slide from "react-reveal";

const Desc = () => {
    return (
        <>
            <section id="desc" style={{ backgroundColor: "#f5f5f5", paddingTop: '10px' }}>
                <Slide left duration={1300}>
                    <div className={styles.grid_container}>
                        <div className={styles.image}>
                            <img src={zdj3} alt="Zdjęcie 1" />
                        </div>
                        <div className={styles.image_description} style={{ justifyContent: "center" }}>
                            <h1>„Szpilki w artystycznym biznesie” <br /><h2>(08.03.2024)</h2></h1>
                        </div>

                        <div className={styles.image}>
                            <img src={zdj1} />
                        </div>
                        <div className={styles.image}>
                            <img src={zdj2} alt="Zdjęcie 2" />
                        </div>
                    </div>
                    {/*<div className={styles.plakat}>
          <img src={plakat} alt="Plakat 1" />
        </div>*/}
                </Slide>
                <Slide left duration={1300}>
                    <div className={styles.grid_container}>
                        <div className={styles.image_description} style={{ justifyContent: "center" }}>
                            <h1>„Dress code w szpilkach” <br /><h2>(30.11.2023)</h2></h1>
                        </div>
                        <div className={styles.image}>
                            <img src={zdj4} alt="Zdjęcie 1" />
                        </div>

                        <div className={styles.image}>
                            <img src={zdj5} />
                        </div>
                        <div className={styles.image}>
                            <img src={zdj6} alt="Zdjęcie 2" />
                        </div>
                    </div>
                    {/*<div className={styles.plakat}>
          <img src={plakat} alt="Plakat 1" />
        </div>*/}
                </Slide>
            </section >
        </>
    );
};

export default Desc;

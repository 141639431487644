import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import background from "../assets/images/background.jpeg";
import tlo from "../assets/images/poprzednie.jpg";
import PoprzednieDesc from "./PoprzednieDesc";
import Footer from "./Footer";
import resumeData from "../assets/resumeData.json";
import ReactGA from "react-ga";
import HeaderNav from "./HeaderNav";
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: "bar",
            resumeData: {}
        };

        ReactGA.initialize("UA-110570651-1");
        ReactGA.pageview(window.location.pathname);
    }

    getResumeData() {
        this.setState({ resumeData: resumeData });
    }

    componentDidMount() {
        this.getResumeData();
    }
    render() {
        const name = "Poprzednie wydarzenia";

        return (
            <>
                <header id="home2">
                    {/*<ParticlesBg type="cobweb" bg={true} color="#AF6464" />*/}
                    <img src={tlo} alt="background" style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }} />

                    <HeaderNav />
                    <div className="row banner">
                        <div className="banner-text">
                            <Fade bottom>
                                <h1 className="responsive-headline" style={{ textShadow: '2px 2px 4px #000000', background: 'rgba(0, 0, 0, 0.5)', padding: '20px', borderRadius: '10px' }}>{name}</h1>
                            </Fade>
                            <hr />
                        </div>
                    </div>

                    <p className="scrolldown">
                        <a className="smoothscroll" href="#about">
                            <i className="icon-down-circle"></i>
                        </a>
                    </p>
                </header>
                <PoprzednieDesc />
                <Footer data={this.state.resumeData.main} />
            </>
        );
    }
}


export default Header;
